<template>
  <div class="datepicker-input">
    <PassportInput v-bind="{ ...$attrs, ...$props }" v-on="$listeners" />
    <IconCalendar class="icon-calendar" />
    <PassportButton v-if="value" class="clear" variant="text" @click="$emit('clear')" tabindex="-1">
      <IconX class="icon-x" />
    </PassportButton>
  </div>
</template>

<script>
import PassportInput from '@/components/PassportInput.vue';
import PassportButton from '@/components/PassportButton.vue';
import IconCalendar from '@/assets/icons/date_icon.svg';
import IconX from '@/assets/icons/x_icon.svg';

export default {
  name: 'default',
  extends: PassportInput,
  components: {
    PassportInput,
    PassportButton,
    IconCalendar,
    IconX,
  },
};
</script>

<style lang="scss" scoped>
.datepicker-input {
  position: relative;

  &:hover,
  &:focus-within {
    .g-button.clear {
      opacity: 1;
    }
  }

  .icon-calendar {
    position: absolute;
    top: 50%;
    left: 0;
    height: 20px;
    margin-left: 10px;
    fill: black;
    transform: translateY(-50%);
    path {
      fill: inherit;
    }
  }
  .g-input {
    ::v-deep {
      .input-element {
        padding-right: 20px;
        padding-left: 40px;
      }
    }
  }
  .g-button.clear {
    position: absolute;
    top: 50%;
    right: 5px;
    display: flex;
    align-items: center;
    padding: 7px;
    color: black;
    opacity: 0;
    transition: opacity 0.15s ease;
    transform: translateY(-50%);

    .icon-x {
      width: 15px;
    }

    &:not(&:disabled) {
      &:hover,
      &:focus-visible {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }
}
</style>
